<template>
  <v-app>
    <dashboard-core-app-bar />

    <dashboard-core-drawer />

    <dashboard-core-view />

    <!-- <dashboard-core-settings /> -->

    <!-- <dashboard-core-footer /> -->
  </v-app>
</template>

<script>
// import { mapState } from 'vuex'
  import axios from 'axios'
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
  axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')

  export default {
    name: 'DashboardIndex',

    components: {
      DashboardCoreAppBar: () => import('./components/core/AppBar'),
      DashboardCoreDrawer: () => import('./components/core/Drawer'),
      // DashboardCoreSettings: () => import('./components/core/Settings'),
      DashboardCoreView: () => import('./components/core/View'),
      // DashboardCoreFooter: () => import('./components/core/Footer'),
    },

    data: () => ({
      expandOnHover: false,
    }),

    mounted () {
      if (localStorage.getItem('reloaded')) {
        localStorage.removeItem('reloaded')

        this.setUserInfo()

        // this.getSidebarNotif()
        // setInterval(() => {
        //   this.getSidebarNotif()
        // }, 10000)
      } else {
        localStorage.setItem('reloaded', '1')
        location.reload()
      }
    },

    methods: {
      // async getSidebarNotif () {
      //   const requestBody = null

      //   await axios.post('/v1/g/sidebarNotif', requestBody).then((res) => {
      //     if (res.data.status === 200) {
      //       // console.log(res.data.data.menu_notif)
      //       this.$store.commit('drawer/SET_SIDEBAR_NOTIFICATION', res.data.data.menu_notif)
      //     }
      //   }).catch((e) => {
      //   })
      // },

      setUserInfo () {
        this.$store.commit('user/SET_USER_DATA', localStorage.getItem('userinfo'))
        // localStorage.removeItem('userinfo')
      },
    },
  }
</script>
